import React, { useRef } from 'react';
import { Form, FormInstance, Input, Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label } from '@/interfaces/label.interface';
import { validateSlugField } from '@/utils/validations';
import JoditEditor from 'jodit-react';

export interface LabelFormProps {
  formRef: FormInstance<Label>;
  onSubmit?: (values: Label) => Promise<void>;
}

const LabelForm: React.FC<LabelFormProps> = ({ onSubmit, formRef }) => {
  const { t } = useTranslation();
  const editor = useRef(null);
  const type = Form.useWatch('type', formRef);

  return (
    <Form
      form={formRef}
      name="label-form"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmit}
    >
      <Row gutter={[10, 10]}>
        <Col span={24} xs={12} md={12}>
          <Form.Item
            label={t('general.slug')}
            name="slug"
            rules={[
              {
                max: 30,
                min: 4,
                required: true,
                type: 'string',
                whitespace: true,
              },
              {
                validator: validateSlugField,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={24} xs={12} md={6}>
          <Form.Item
            label={t('general.language')}
            name="language"
            initialValue={'*'}
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                {
                  label: t('languages.all'),
                  value: '*',
                },
                {
                  label: t('languages.en'),
                  value: 'en',
                },
                {
                  label: t('languages.es'),
                  value: 'es',
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={24} xs={12} md={6}>
          <Form.Item
            label={t('general.country')}
            name="country"
            initialValue={'*'}
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                {
                  label: t('languages.all'),
                  value: '*',
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={24} xs={12} md={6}>
          <Form.Item
            label={t('general.section')}
            name="section"
            initialValue={'*'}
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                {
                  label: t('general.any'),
                  value: '*',
                },
                {
                  label: t('services.mail'),
                  value: 'email',
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={24} xs={12} md={6}>
          <Form.Item
            label={t('general.type')}
            name="type"
            initialValue="text"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                {
                  label: t('general.text'),
                  value: 'text',
                },
                {
                  label: 'HTML',
                  value: 'html',
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <div style={{ display: type === 'text' ? 'block' : 'none' }}>
            <Form.Item
              label={t('general.text')}
              name="text"
              rules={[{ required: true, type: 'string', whitespace: true }]}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          </div>
          <div style={{ display: type === 'html' ? 'block' : 'none' }}>
            <JoditEditor
              ref={editor}
              value={formRef.getFieldValue('text')}
              config={{ readonly: false, height: 300, tabIndex: 1 }}
              onBlur={(content) => formRef.setFieldValue('text', content)}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default LabelForm;
