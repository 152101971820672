import { Breadcrumb, Card, Col, Divider, Row, Modal, message, Button } from 'antd';
import { HomeOutlined, ReadOutlined } from '@ant-design/icons';
import LabelsTable from '@/components/labels/LabelsTable';
import LabelsModal from '@/components/labels/LabelsModal';

// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
// API
import APIService from '@/services/API';
import { Label } from '@/interfaces/label.interface';

const LabelsScreen = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    $skip: '0',
    $limit: '10',
  });
  const [modal, setModal] = useState({
    type: 'create',
    visible: false,
    data: {},
  });
  const { isLoading, data, refetch } = useQuery(['labels', params], () =>
    APIService.getLabels(params),
  );
  const handleDelete = async (id: string) => {
    const response = await APIService.deleteLabel(id);
    if (response.ok) {
      refetch();
      message.success(t('labels.deleteSuccess'));
    } else {
      message.error(response.data?.message);
    }
  };
  const onDelete = (id: string) => {
    Modal.confirm({
      title: t('labels.deleteConfirmation'),
      okText: t('general.yes'),
      onOk: () => handleDelete(id),
    });
  };
  const onEdit = (data: Label) => {
    // Hanlde edit user, open edition modal
    setModal({
      visible: true,
      type: 'edit',
      data,
    });
  };
  return (
    <div className="dashboard-screen fadeIn">
      <LabelsModal
        visible={modal.visible}
        edit={modal.type === 'edit'}
        data={modal.data}
        onOk={() => {
          refetch();
          setModal({ visible: false, type: 'create', data: {} });
        }}
        onCancel={() => {
          setModal({ visible: false, type: 'create', data: {} });
        }}
      />
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Breadcrumb
              items={[
                {
                  href: '',
                  title: <HomeOutlined />,
                },
                {
                  href: '',
                  title: (
                    <>
                      <ReadOutlined />
                      <span>{t('menu.labels')}</span>
                    </>
                  ),
                },
              ]}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() =>
                setModal({
                  visible: true,
                  type: 'create',
                  data: {},
                })
              }
            >
              {t('labels.create')}
            </Button>
          </Col>
        </Row>
        <Divider />
        <LabelsTable
          onFilter={setParams}
          data={data?.data}
          loading={isLoading}
          total={data?.total}
          current={(data?.skip || 0) / (data?.limit || 1)}
          {...{ onDelete, onEdit }}
        />
      </Card>
    </div>
  );
};

export default LabelsScreen;
