import { Button, Table, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { parseFilter } from '@/utils/filter';
import { useTranslation } from 'react-i18next';
import { Label, RawLabel } from '@/interfaces/label.interface';

export interface LabelsTableProps {
  data?: RawLabel[];
  total?: number;
  current?: number;
  loading?: boolean;
  onFilter?: (value: any) => any;
  onEdit?: (data: Label) => void;
  onView?: (data: Label) => void;
  onDelete?: (id: string) => void;
}

const LabelsTable = ({
  data,
  total,
  loading,
  onFilter,
  onDelete,
  onEdit,
}: LabelsTableProps) => {
  const { t } = useTranslation();

  const Columns: ColumnsType<RawLabel> = [
    {
      title: t('general.slug'),
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: t('general.language'),
      dataIndex: 'language',
      key: 'language',
    },
    {
      title: t('general.country'),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: t('general.section'),
      dataIndex: 'section',
      key: 'section',
    },
    {
      title: t('general.createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) =>
        created_at && new Date(created_at).toLocaleDateString(),
    },
    {
      title: t('general.type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('general.actions'),
      key: '_id',
      render: (text: string, record: RawLabel) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit?.(record)}>
            {t('general.edit')}
          </Button>
          <Button type="link" onClick={() => onDelete?.(record._id)}>
            {t('general.delete')}
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnChange = (
    pagination: any = {},
    filters: any = {},
    sorter: any,
    { action }: { action: string },
  ) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...value,
        ...parseFilter(filters),
        $skip: pagination.current ? (pagination.current - 1) * 10 : 0,
      }));
    }
  };

  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={Columns}
      loading={loading}
      onChange={handleOnChange}
      rowKey={(e) => e._id}
      pagination={{
        total,
        defaultPageSize: 10,
        showTotal: (total) => t('general.total', { total }),
      }}
    />
  );
};

export default LabelsTable;
