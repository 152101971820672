import React, { useEffect } from 'react';
import { Modal, Form, message } from 'antd';
import LabelForm from '../forms/LabelForm';
// Hooks
import { useTranslation } from 'react-i18next';
// Interfaces
import { Label } from '@/interfaces/label.interface';
// Services
import APIService from '@/services/API';

export interface LabelsModalProps {
  visible: boolean;
  edit?: boolean;
  data?: Record<string, string | number>;
  onCancel?: () => void;
  onOk?: () => void;
}
const LabelsModal: React.FC<LabelsModalProps> = ({
  visible,
  edit,
  onCancel,
  onOk,
  data,
}) => {
  const [formRef] = Form.useForm<Label>();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (edit && data) {
      formRef.setFieldsValue(data);
    }
  }, [edit, data, formRef]);

  const handleOnSubmit = async (values: Label) => {
    setLoading(true);
    const response = data?._id
      ? await APIService.updateLabel(data?._id as string, values)
      : await APIService.createLabel(values);
    if (response.ok) {
      if (onOk) {
        onOk();
      }
      formRef.resetFields();
      if (edit) {
        message.success(t('labels.updateSuccess'));
      } else {
        message.success(t('labels.createSuccess'));
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      centered
      open={visible}
      title={edit ? t('labels.edit') : t('labels.create')}
      onOk={() => formRef.submit()}
      width={700}
      onCancel={() => {
        onCancel?.();
        formRef.resetFields();
      }}
      okButtonProps={{ loading }}
    >
      <LabelForm formRef={formRef} onSubmit={handleOnSubmit} />
    </Modal>
  );
};
export default LabelsModal;
